import axiosInstance from "../lib/axiosInstance.js";

class OrderBetService {
    #url = "/ab/rest/cpanel/orderbets";

    getOrderBetsList(page, filter) {
        var params = {
            page: page
        };

        var data = Object.assign({}, params, filter);

        return axiosInstance.get(this.#url, {
            params: data
        });
    }

    getOrderBetsListGroup(page, filter) {
        var params = {
            page: page
        };

        var data = Object.assign({}, params, filter);

        return axiosInstance.get(this.#url + '/group', {
            params: data
        });
    }

    getOrderBet(id) {
        return axiosInstance.get(this.#url+"/"+id)
    }

    setStatusManually(id, status) {
        return axiosInstance.post(this.#url+"/status/"+id, {
            status: status
        })
    }
}

export default new OrderBetService();