
const BookmakerType = {
    1: "Pinnacle",
    3: "FBroker",
    4: "Vodds"
};

const SportType = {
    1: "Football",
    2: "Tennis",
    3: "Basketball",
    4: "Baseball",
    5: "MMA",
    6: "Boxing",
    18: "E-Sports",
    21: "American Football",
    25: "Hockey"
}

export {BookmakerType, SportType}