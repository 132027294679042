<template>
    <div v-if="null != orderBet" class="order-bet-single">
      <div class="row mb-2">
        <div class="col-4">
          <h3>#{{orderBet.id}}</h3>
        </div>
        <div v-if="['PLAY','WON', 'OPEN', 'NEW'].indexOf(orderBet.status)>=0"
            class="col-8 justify-content-end d-flex">
          <div style="width: 150px; display: inline-block">
            <b-form-select v-if="orderBet.status === 'PLAY'"
                         v-model="manualStatus"
                         :options="manualStatuses"
                         aria-placeholder="sss"
                         class="mr-1"></b-form-select>
            <b-form-select v-if="orderBet.status === 'WON'"
                           v-model="manualStatus"
                           :options="manualStatusesWon"
                           aria-placeholder="sss"
                           class="mr-1"></b-form-select>
            <b-form-select v-if="['OPEN', 'NEW'].indexOf(orderBet.status) >= 0"
                           v-model="manualStatus"
                           :options="manualStatusesOpen"
                           aria-placeholder="sss"
                           class="mr-1"></b-form-select>
          </div>
          <button class="btn btn-primary" v-on:click="setStatusManually">Установить статус</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table id="order-bet-list-table" class="table order-bet-list-table">
            <tr>
              <th>ID</th>
              <th>Event</th>
              <th>Order ID</th>
              <th>Bet Type</th>
              <th>Want</th>
              <th>Actual</th>
              <th>Risk</th>
              <th>Profit</th>
              <th>Status</th>
              <th class="text-center">Last Price</th>
              <th class="text-center">UDI</th>
              <th class="text-right">Created At</th>
            </tr>
            <tr>
              <td width="150px">{{ orderBet.id }}</td>
              <td> {{ getBookmakerName(orderBet.bookmakerId) }}. {{getSportName(orderBet.event.sport)}}.
                {{ orderBet.event.tournamentName}}<br/>
                {{ orderBet.event.home }} - {{ orderBet.event.away }}<br/>
                {{ orderBet.event.eventId }}<br/>
                {{ new Date(orderBet.event.playAt) | moment("YYYY-MM-DD HH:mm") }}<br/>
                <a v-if="null != orderBet.blog"
                   :href="getBlogUrl(orderBet.blog.id)"
                   target="_blank">{{orderBet.blog.title}}</a>
              </td>
              <td width="150px">{{ orderBet.orderId }}</td>
              <td>
                <p class="mb-1">
                  <span v-if="orderBet.betName=='special'">{{orderBet.description}}</span>
                  <span v-else>{{orderBet.betName}}</span>     
                </p>
                <p class="mb-1">{{ orderBet.period }}</p>
                <p class="mb-1" v-if="orderBet.selectedBook">
                  {{orderBet.selectedBook}}
                </p>
              </td>
              <td>
                {{ orderBet.wantStake }} / {{ orderBet.wantPrice }}
                <br/>
                <small>[Origin: {{ orderBet.originWantStake }}]</small>
              </td>
              <td>{{ orderBet.stake }} / {{ orderBet.price }}</td>
              <td>{{ orderBet.minRisk }} - {{ orderBet.maxRisk }}</td>
              <td>{{ orderBet.profit }}</td>
              <td>
                {{ orderBet.status }}
                <template v-if="orderBet.isPartialVoid">
                  <br/><b class="text-danger">Partial Void</b>
                </template>
                <p class="mb-1" v-if="orderBet.cancelReason">
                  {{orderBet.cancelReason}}
                </p>
              </td>
              <td class="text-center">{{ orderBet.lastPrice }}</td>
              <td class="text-center">{{ orderBet.udi }}</td>
              <td class="text-right">
                {{ new Date(orderBet.createdAt) | moment("YYYY-MM-DD HH:mm") }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h3>Ставки пользователей</h3>
          <table class="table">
            <tr>
              <th>Id</th>
              <th>Пользователь</th>
              <th>Stake</th>
              <th>Price</th>
              <th>Profit</th>
              <th>Отчисление</th>
              <th>Коммисия</th>
              <th>Status</th>
              <th>Stake Play</th>
            </tr>
            <tr v-for="ub in usersBets" v-bind:key="ub.id">
              <td>{{ub.id}}</td>
              <td>
                <a :href="'/user/'+ub.user.id" target="_blank">{{ub.user.username}}</a>
              </td>
              <td>
                {{ub.stake}}
                <br>
                <small>[Origin: {{ub.originWantStake}}]</small>
              </td>
              <td>{{ub.price}}</td>
              <td>{{ub.profit}}</td>
              <td>{{ub.deduction}}</td>
              <td>
                <span v-if="null != ub.planCommission">{{ ub.planCommission }}</span>
              </td>
              <td>{{ub.status}}</td>
              <td>{{ub.stakePlay}}</td>
            </tr>
            <tfoot class="bg-light">
            <tr>
              <td>Итого</td>
              <td></td>
              <td>
                {{ orderBet.stake }}
                <br/>
                <small>[Origin: {{ sumOriginStake() }}]</small>
              </td>
              <td>{{ orderBet.price }}</td>
              <td>{{ orderBet.profit}}</td>
              <td>{{ orderBet.deduction }}</td>
              <td>{{ sumPlanCommission() }}</td>
              <td></td>
              <td>
                {{ sumStakePlay() }}
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
    import OrderBetService from "../services/OrderBetService";
    import UserBetService from "../services/UserBetService";
    import {BookmakerType, SportType} from "../services/enums"

    export default {
        name: "OrderBetsSingle",
        data() {
            return {
                orderBet: null,
                usersBets: [],
              manualStatus: null,
              manualStatuses: [
                {value: null, text:'Статус'},
                {value: 'LOSS', text:'Loss'},
                {value: 'REFUND', text:'Refund'},
                {value: 'CANCEL', text:'Cancel'},
                {value: 'REJECTED_ABNORMAL', text:'Reject abnormal'}
              ],
              manualStatusesWon: [
                {value: null, text:'Статус'},
                {value: 'CANCEL', text:'Cancel'}
              ],
              manualStatusesOpen: [
                {value: null, text:'Статус'},
                {value: 'CANCEL', text:'Cancel'}
              ]
            }
        },
        mounted() {
            if (null != this.$route.params.id) {
                this.loadOrderData(this.$route.params.id)
            }
        },
        methods: {
            loadOrderData(orderId) {
              let self = this;
              let orderPromice = OrderBetService.getOrderBet(orderId)
                  .then((response) => {
                    self.orderBet = response.data
                  })
              UserBetService.getOrderBetsByOrder(orderId)
                  .then((response) => {
                    self.usersBets = response.data.content
                  })
              return orderPromice;
            },

            getBookmakerName(id) {
                return BookmakerType[id];
            },
            getSportName(id) {
                return SportType[id];
            },
            getBlogUrl(id) {
                return 'https://expari.com/blog/' + id
            },
            sumPlanCommission() {
                var self = this;
                var s = 0;
                for(var i =0; i<self.usersBets.length; i++ ) {
                    var ub = self.usersBets[i];
                    if (ub.planCommission && ub.planCommission>0) {
                        s = s + ub.planCommission;
                    }
                }
                return s;
            },
            sumStakePlay() {
                var self = this;
                var s = 0;
                for(var i =0; i<self.usersBets.length; i++ ) {
                    var ub = self.usersBets[i];
                    if (ub.stakePlay && ub.stakePlay>0) {
                        s = s + ub.stakePlay;
                    }
                }
                return s;
            },
          sumOriginStake() {
            var self = this;
            var s = 0;
            for(var i =0; i<self.usersBets.length; i++ ) {
              var ub = self.usersBets[i];
              if (ub.originWantStake && ub.originWantStake>0) {
                s = s + ub.originWantStake;
              }
            }
            return s;
          },
            setStatusManually() {
              var self = this;
              // if (self.orderBet.status !== 'PLAY') {
              //   alert("Можно изменить только заказ со статустом 'PLAY'")
              //   return;
              // }

              if (self.manualStatus === null) {
                alert("Выберите новый статус");
                return;
              }

              let message = `Устаноить новый статус ${self.manualStatus}? Действие нельзя будет отменить.`;

              if (confirm(message)) {
                OrderBetService.setStatusManually(self.orderBet.id, self.manualStatus)
                .then((response) => {
                  if (response.data.status === "ok") {
                    self.loadOrderData(self.orderBet.id)
                        .then(() => {
                          self.manualStatus = null;
                        })
                  } else {
                    alert(response.data.message)
                  }

                });
              }
            }
        }
    }
</script>
